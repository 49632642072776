<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card class="w-100 rounded-xl py-4">
        <v-card-title class="px-8 d-flex justify-center">{{ $t('login.newPassword') }}</v-card-title>
        <v-card-text class="px-8">
            <v-form ref="loginForm" v-model="valid">
                <v-text-field
                v-model="password"
                type="password"
                :label="$t('login.password')"
                dense
                single-line
                validate-on-blur
                :rules="passwordRules"
                ></v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions class="px-8 d-flex justify-center">
            <v-btn btn color="primary" class="px-8" depressed :disabled="submitLoading" :loading="submitLoading" @click="submitHandle" rounded>{{ $t('login.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Service from '@/services'
import * as mutationTypes from '@/store/mutation-types'
export default {
  name: 'NewPass',
  props: ['email', 'oldPassword'],
  data () {
    return {
      dialog: false,
      password: '',
      submitLoading: false,
      valid: true,
      passwordRules: [
        v => !!v.trim() || this.$t('validation.passwordRequired')
      ]
    }
  },
  methods: {
    show () {
      this.dialog = true
    },
    async getCountries () {
      try {
        const { data } = await Service.get('Tenants/?onlyCountries=true')
        this.$store.dispatch('setCountries', data)
      } catch (error) {
      }
    },
    async submitHandle () {
      if (!this.$refs.loginForm.validate()) {
        return
      }
      this.submitLoading = true
      try {
        const { data } = await Service.post('Authentication/SetNewPassword', {
          email: this.email,
          oldPassword: this.oldPassword,
          newPassword: this.password
        })

        if (data.token) {
          localStorage.setItem('token', data.token)
          localStorage.setItem('tenantValue', 'PL')
          localStorage.setItem('currency', 'zł')
          this.$store.commit(mutationTypes.LOGIN, true)
          await this.$router.push({ path: '/orders-wrap' })
          this.getCountries()
        }

        this.$refs.loginForm.resetValidation()
      } catch (e) {
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>
